@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
.ml-10 {
  margin-left: 10px;
}

.text-decoration:hover {
  text-decoration: none !important;
}

.menudivider {
  border-color: #dddddd;
  margin: 10px 15px;
}

.login {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

svg.favouriteicon {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

svg.favouriteicon path {
  transition: all 0.2s ease-in-out;
}

svg.favouriteicon:hover path {
  fill: #e51a2b;
  fill-opacity: 1;
}

.img24 {
  width: 24px !important;
  height: 24px !important;
}

.chakra-toast:first-child {
  margin-top: 50px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.52);
  z-index: 1;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.morecollectionimg {
  height: 284px;
  width: 285px;
  -o-object-fit: cover;
  object-fit: cover;
}

.pagination ul {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}

.pagination > li {
  list-style: none;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #e51a2b;
  border-color: #e51a2b;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: #e51a2b;
  color: white;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  background-color: transparent;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  margin-right: 0;
  background-color: transparent;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
}

.pagination > li:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  border-left: 0;
}

.pagination > li:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
}

.pagination .page-item {
  border: 1px solid #e51a2b;
}
/*# sourceMappingURL=globals.css.map */

